@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
body {
  font-family: "Lato", sans-serif !important;
}


.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl {
    margin: 0 0 212px 260px !important;
    float: left;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
}
.mapbox.xgl-ctrl-attrib-inner {
  display: none !important;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply py-2 px-3 m-2 rounded;
}
.btn-primary {
  @apply bg-blue-700 text-white 
  hover:bg-blue-800;
}
.btn-danger {
  @apply bg-red-700 text-white
  hover:bg-red-800;
}

#bg {
  background-image: url("./bg.jpg");
  background-size: cover;
}

.right-0 {
  right: 0px;
}

.right-half {
  right: 50%;
}


.left-full{
  left: 100%;
}
.distance-container {
  position: absolute;
  top: 100px;
  left: 300px;
  z-index: 1;
  }
   
  .distance-container > * {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 11px;
  line-height: 18px;
  display: block; 
  margin: 0;
  padding: 5px 10px;
  border-radius: 3px;
  }